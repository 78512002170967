
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("充币列表", ["充币管理;add_point_records"])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      nickname: '',
      mobile: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_ADD_POINT_RECORDS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentAddPointRecords
          page.value.totalResult = store.getters.currentAddPointRecordCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const submitEvent = () => {
      query()
    }

    const newAddPointRecords = () => {
      router.push({ name: "add_point_record_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.nickname = ''
      formData.value.mobile = ''
    }

    return {
      tableData,
      formData,
      submitEvent,
      newAddPointRecords,
      page,
      onPageChange,
      onCancel
    }
  },
})
